import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { getFetchQuery } from 'pipes/qrs/actions/pipeListActions';
import api from 'core/utils/api';
import { gettext } from 'core/utils/text';
import reverseUrl from 'core/utils/urls';
import FilterInput from './FilterInput';

const ExportState = ({ canExportData, exportState, onDownload }) => {
    const unknownState = gettext('Unknown state');

    if (!canExportData) {
        return null;
    }
    switch (exportState) {
        case 'no_export':
            return (
                <button className="btn btn-link disabled">
                    {gettext('No export')}
                </button>
            );
        case 'in_progress':
            return (
                <button className="btn btn-link disabled">
                    {gettext('Generating export...')}
                </button>
            );
        case 'done':
            return (
                <button className="btn btn-link" onClick={onDownload}>
                    {gettext('Export ready')}
                </button>
            );
        case 'failed':
            return (
                <button className="btn btn-link disabled">
                    {gettext('Export failed')}
                </button>
            );
        case 'loading':
            return (
                <button className="btn btn-link disabled">
                    {gettext('Loading...')}
                </button>
            );
        default:
            return (
                <div>
                    {unknownState}: {exportState}
                </div>
            );
    }
};

ExportState.propTypes = {
    canExportData: PropTypes.bool.isRequired,
    exportState: PropTypes.string.isRequired,
    onDownload: PropTypes.func.isRequired,
};

export default class FilterBar extends Component {
    static propTypes = {
        onChange: PropTypes.func,
        onReset: PropTypes.func,
        isDirty: PropTypes.bool,
        listFilters: PropTypes.shape().isRequired,
        canExportData: PropTypes.bool.isRequired,
        children: PropTypes.node,

        machineOperationMode: PropTypes.string,

        // See `class MachineOperationModes`
    };

    constructor(props) {
        super(props);
        this.state = {
            inputs: [],
            filterSet: {},
            query: {},
            counter: 0,
            exportState: 'loading',
            ...(DJ_CONST.DEFAULT_FILTERS || {}),
        };
        if (props.canExportData) {
            this.actionGetExportState();
            setInterval(this.actionGetExportState, 5000);
        }
    }

    appendInput = () => {
        const newInput = `input-${this.state.counter}`;
        this.setState({
            inputs: this.state.inputs.concat([newInput]),
            counter: this.state.counter + 1,
        });
    };

    deleteInput(key) {
        const newFilterSet = { ...this.state.filterSet };
        delete newFilterSet[key];
        this.setState(
            {
                inputs: this.state.inputs.filter((value) => value !== key),
                filterSet: newFilterSet,
            },
            this.makeQuery,
        );
    }

    deleteAll = () => {
        this.props.onReset();
        this.setState(
            {
                inputs: [],
                filterSet: {},
                query: {},
                counter: 0,
            },
            Object.keys(this.state.query).length ? this.makeQuery : null,
        );
    };

    makeQuery() {
        const query = {};
        this.state.inputs.forEach((inputKey) => {
            const currentFilter = this.state.filterSet[inputKey];
            if (currentFilter) {
                Object.keys(currentFilter).forEach((filterKey) => {
                    if (query[filterKey]) {
                        query[filterKey] = query[filterKey].concat(
                            currentFilter[filterKey],
                        );
                    } else if (Array.isArray(currentFilter[filterKey])) {
                        query[filterKey] = currentFilter[filterKey];
                    } else {
                        query[filterKey] = [currentFilter[filterKey]];
                    }
                });
            }
        });
        this.setState({ query }, () => this.props.onChange(this.state.query));
    }

    update(filter, key) {
        const newFilterSet = { ...this.state.filterSet, [key]: filter };
        this.setState({ filterSet: newFilterSet }, () => this.makeQuery());
    }

    // Get the current state and keep it in sync locally
    actionGetExportState = () =>
        api.pipesExport
            .fetch(null, { action: 'get_state' })
            .then(({ export_state: currState }) =>
                this.setState({ exportState: currState }),
            )
            .catch((error) =>
                console.error(`Unable to get export state: ${error}`),
            );

    // Trigger celery task to create export
    actionStartExport = () => {
        const startExportInner = () => {
            const fetchQuery = getFetchQuery(this.props.listFilters);
            api.pipesExport
                .post(null, { action: 'start_export', query: fetchQuery })
                .then(this.actionGetExportState);
        };
        const confirm_start_text = gettext(
            'Are you sure that you wish to generate a report? The system only allows to generate 1 report a time.',
        );
        const pipe_limit_text = `(${gettext(
            'System only exports up to {count} pipes counting from backwards to forward.',
        ).replace('{count}', DJ_CONST.REPORTS_EXPORT_MAX_PIPES_F_STR)})`;

        return this.actionGetExportState().then(() => {
            switch (this.state.exportState) {
                case 'in_progress':
                    alert(gettext('Export generation already in progress'));
                    return;
                case 'done':
                    if (
                        window.confirm(
                            confirm_start_text +
                                '\n\n' +
                                gettext(
                                    'There is an existing export that will be overwritten. Continue?',
                                ) +
                                '\n\n' +
                                pipe_limit_text,
                        )
                    ) {
                        startExportInner();
                    }
                    return;
                case 'no_export':
                case 'failed':
                    if (
                        window.confirm(
                            confirm_start_text + '\n\n' + pipe_limit_text,
                        )
                    ) {
                        startExportInner();
                    }
                    return;
                default:
                    console.error(
                        `Unhandled pipe export state: ${this.state.exportState}`,
                    );
                    alert(gettext('Unknown error occurred!'));
            }
        });
    };

    // Download the export
    actionGetExport = () => {
        this.actionGetExportState().then(() => {
            if (this.state.exportState === 'done') {
                window.location = reverseUrl('pipes-export');
            } else {
                alert(gettext('No export to download!'));
            }
        });
    };

    render = () => {
        const { canExportData, isDirty } = this.props;
        return (
            <div className="filter-block">
                <div id="dynamicInput">
                    {this.state.inputs.map((key) => (
                        <FilterInput
                            key={key}
                            fieldKey={key}
                            machineOperationMode={
                                this.props.machineOperationMode
                            }
                            initial={this.state.filterSet[key]}
                            onDelete={() => this.deleteInput(key)}
                            onChange={(filter) => this.update(filter, key)}
                        />
                    ))}
                </div>
                <button
                    className="btn btn-primary btn-success search-button"
                    onClick={this.appendInput}
                >
                    {gettext('Add filter')}
                </button>
                <button
                    className="btn btn-primary btn-danger search-button"
                    onClick={this.deleteAll}
                >
                    {gettext('Reset all filters')}
                </button>

                {this.props.children}

                {canExportData && (
                    <button
                        className="btn btn-primary btn-success search-button"
                        onClick={this.actionStartExport}
                    >
                        {gettext('Generate report')}
                    </button>
                )}

                <ExportState
                    canExportData={canExportData}
                    exportState={this.state.exportState}
                    onDownload={this.actionGetExport}
                />
                {isDirty && (
                    <span className="search-disabled-text text-danger">
                        {gettext(
                            "You won't be able to filter until you save your changes.",
                        )}
                    </span>
                )}
            </div>
        );
    };
}
