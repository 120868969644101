import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import * as Sentry from '@sentry/react';
import { createLogger } from 'redux-logger';
import createSagaMiddleware from 'redux-saga';

const __DEV__ = process.env.NODE_ENV === 'development';

const composeEnhancers =
    typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
        ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
        : compose;

export default function createReduxStore(reducer, initialState = undefined) {
    /* eslint-disable */
    const sagaMiddleware = createSagaMiddleware();
    const middleware = [thunk, sagaMiddleware];

    if (__DEV__) {
        middleware.push(createLogger({ collapsed: true }));
    }
    /* eslint-enable */
    const sentryReduxEnhancer = Sentry.createReduxEnhancer();

    // might contain pre-configured enhancers so start with ours
    const enhancer = composeEnhancers(
        applyMiddleware(...middleware),
        sentryReduxEnhancer,
    );

    return {
        store: createStore(reducer, initialState, enhancer),
        sagaMiddleware,
    };
}
