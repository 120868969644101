import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import Modal from 'tg-modal';

import { gettext } from 'core/utils/text';

export default class extends Component {
    static propTypes = {
        title: PropTypes.string.isRequired,
        isOpen: PropTypes.bool.isRequired,
        isValid: PropTypes.bool,
        isStatic: PropTypes.bool,
        onConfirm: PropTypes.func,
        onCancel: PropTypes.func.isRequired,
        children: PropTypes.node,
        yesText: PropTypes.string,
        noText: PropTypes.string,
    };

    static defaultProps = {
        isValid: true,
        yesText: gettext('Yes'),
        noText: gettext('No'),
        isStatic: false,
    };

    onCancel = (e) => {
        e.stopPropagation();
        this.props.onCancel();
    };

    onConfirm = () => {
        this.props.onConfirm();
    };

    render() {
        return (
            <div>
                <Modal
                    isOpen={this.props.isOpen}
                    onCancel={this.onCancel}
                    onConfirm={this.onConfirm}
                    isStatic={this.props.isStatic}
                >
                    <Modal.Header addClose={false}>
                        {this.props.title}
                    </Modal.Header>

                    {this.props.children ? (
                        <Modal.Body>{this.props.children}</Modal.Body>
                    ) : null}
                    <div className="tg-modal-footer">
                        <Button
                            bsStyle="danger"
                            className="btn-half"
                            onClick={this.onCancel}
                        >
                            {this.props.noText}
                        </Button>
                        <Button
                            bsStyle="success"
                            className="btn-half"
                            disabled={!this.props.isValid}
                            onClick={this.onConfirm}
                        >
                            {this.props.yesText}
                        </Button>
                    </div>
                </Modal>
            </div>
        );
    }
}
